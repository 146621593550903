import { useRouter } from 'next/router';
import { useCallback } from 'react';
export const useNavigation = ()=>{
    const router = useRouter();
    /**
   * Navigate to an internal or external URL.
   *
   * Uses next/router for internal links and window.location.href for external.
   */ const navigate = useCallback((href)=>{
        const isAbsolute = /^https?:\/\//i.test(href);
        if (!isAbsolute) {
            void router.push(href);
            return;
        }
        const { pathname, host } = new URL(href);
        if (/^(www\.)?uppbeat\.io$/.test(host)) {
            void router.push(pathname);
            return;
        }
        window.location.href = href;
    }, [
        router
    ]);
    return {
        navigate
    };
};
